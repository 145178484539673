import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import lightTheme from '@/src/styles/themes/lightTheme';
import msmeLogo from '@/public/images/msme_logo.svg';
import Login from '@/src/components/common/Login';

const LoginPage = () => {
  const location = useLocation(); // Get location object
  const searchParams = new URLSearchParams(location.search); // Parse query parameters
  const redirectTo = searchParams.get('redirectTo') ?? '/'; // Extract redirectTo
  return (
    <Box
      className="content-center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Card
        sx={{
          zIndex: 1,
          width: lightTheme.spacing(60),
          boxShadow: 3,
          borderRadius: 2
        }}
      >
        <CardContent
          sx={{
            padding: (lightTheme) => `${lightTheme.spacing(5)} !important`
          }}
        >
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={msmeLogo} alt="MSME Logo" width={200} />
          </Box>
          <Box sx={{ mb: 8 }}>
            <Typography variant="body2">Please sign-in to your account</Typography>
          </Box>
          <Login redirectTo={redirectTo} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
