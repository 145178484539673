import { MouseEventHandler } from 'react';
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

type SaveAndCancelProps = {
  handleCancel?: MouseEventHandler;
  disableSave?: boolean;
  disableReset?: boolean;
  isLoading?: boolean;
};

const SaveAndCancel = (props: SaveAndCancelProps) => {
  const { handleCancel, disableSave, disableReset, isLoading } = props;

  return (
    <Box
      alignItems="center"
      position="absolute"
      width="100%"
      bgcolor="white"
      bottom={20}
      right={10}
    >
      <Box display="flex" justifyContent="end" alignItems="center" p={1}>
        <Box display="flex" gap={1} height={30} sx={{ mr: 1 }}>
          {handleCancel && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancel}
              disabled={disableReset}
              sx={{
                borderRadius: 2.5
              }}
            >
              Reset
            </Button>
          )}
          {/* confirm button */}
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={disableSave}
            loading={isLoading ?? false}
            sx={{
              borderRadius: 2.5
            }}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveAndCancel;
