/* eslint-disable no-unused-vars */
import { IGetApiRequestParams, ILooseObject } from '@/src/types/common.interface';
import AppUtils from '@/src/utils/AppUtils';

export enum ALL_USERS_API_URLS_ENUM {
  GET_ALL_USERS_LIST = 'GET_ALL_USERS_LIST',
  GET_ALL_ORGANIZATION_LIST = 'GET_ALL_ORGANIZATION_LIST',
  GET_ALL_USER_TYPE_LIST = 'GET_ALL_USER_TYPE_LIST',
  POST_ADD_NEW_USER = 'POST_ADD_NEW_USER',
  GET_USER_DETAIL_BY_USER_KEY = 'GET_USER_DETAIL_BY_USER_KEY',
  GET_ALL_APPLICATIONS_BY_ORGANIZATION = 'GET_ALL_APPLICATIONS_BY_ORGANIZATION',
  GET_ROLES_BY_APPLICATIONS = 'GET_ROLES_BY_APPLICATIONS',
  DELETE_USER_BY_USER_KEY = 'DELETE_USER_BY_USER_KEY'
}

type GET_ALL_USERS_API_URL_TYPE = IGetApiRequestParams & {
  url: ALL_USERS_API_URLS_ENUM;
  data?: ILooseObject;
};

abstract class AllUsersApiConfig {
  public static GetAllUsersApiUrl({
    url,
    data,
    filters,
    pagination,
    sorting
  }: GET_ALL_USERS_API_URL_TYPE) {
    switch (url) {
      case ALL_USERS_API_URLS_ENUM.GET_ALL_USERS_LIST:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/users`,
          { pagination, sorting, filters }
        );

      case ALL_USERS_API_URLS_ENUM.GET_ALL_ORGANIZATION_LIST:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/organizations`,
          { filters }
        );

      case ALL_USERS_API_URLS_ENUM.GET_ALL_USER_TYPE_LIST:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/user/user_types`;

      case ALL_USERS_API_URLS_ENUM.POST_ADD_NEW_USER:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/user`;

      case ALL_USERS_API_URLS_ENUM.GET_USER_DETAIL_BY_USER_KEY:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/user/${data?.userKey}`;

      case ALL_USERS_API_URLS_ENUM.GET_ALL_APPLICATIONS_BY_ORGANIZATION:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/organization/${data?.organizationKey}`;

      case ALL_USERS_API_URLS_ENUM.GET_ROLES_BY_APPLICATIONS:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/roles`,
          { filters }
        );

      case ALL_USERS_API_URLS_ENUM.DELETE_USER_BY_USER_KEY:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/user/${data?.userKey}`;
    }
  }
}

export default AllUsersApiConfig;
