import { createTheme, Theme } from '@mui/material/styles';

// Creating a theme instance.
const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: '#fff'
    },
    grey: {
      '100': '#f8fafb'
    },
    primary: {
      main: '#2241A6',
      dark: '#1E3565'
    },
    text: {
      primary: '#262626'
    },
    action: {
      active: '#001E3C'
    }
  },
  typography: {
    fontFamily: ['sourceSansProFont', 'sans-serif']?.join(',')
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filledInfo': {
            backgroundColor: '#EBF8FF', // Customize background color for info variant
            color: '#5599CD' // Optional: Customize text color
          }
        },
        deleteIcon: {
          '&.MuiChip-deleteIconColorInfo': {
            color: '#5599CD' // Custom icon color for info variant
          }
        }
      }
    }
  }
});

export default lightTheme;
