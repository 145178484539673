import { enqueueSnackbar } from 'notistack';
import { Box, Button, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ApiClient from '@/src/utils/ApiClient';
import UseNavigation from '@/src/hooks/useNavigation';
import { IApiClientResponse, ILooseObject, IPagination } from '@/src/types/common.interface';
import { store } from '@/src/datastore/store';
import allUsersApiConfig, { ALL_USERS_API_URLS_ENUM } from '@/src/config/api_config/allUsers';
import AppConfig from '@/src/config/AppConfig';
import TableHeadersConfig from '@/src/config/TableHeadersConfig';

export interface IGetAllUsersResult {
  rows: ILooseObject[];
  columns: GridColDef[];
  page: IPagination & ILooseObject;
  error: IApiClientResponse['error'];
}

export interface IGetRolesByPermissionResult {
  rows: ILooseObject[];
  columns: GridColDef[];
  error: IApiClientResponse['error'];
}

export interface IGetAllUsersListParams {
  filters: ILooseObject;
  pagination: IPagination;
}

export interface IGetAllOrganizationParams {
  filters?: ILooseObject;
}

export interface IGetUserDetailByUserKeyParams {
  userKey?: string;
}

export interface IGetApplicationsListByOrganizationParams {
  organizationKey: string;
}

export interface IGetRolesListByApplicationParams {
  applicationKeys: string[];
}

export interface IDeleteUserByUserKeyParams {
  userKey: string;
}

const updateTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header.field === 'business_units') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          if (params?.row?.business_units?.length > 0) {
            return params?.row?.business_units?.map((bu: ILooseObject) => bu?.ui_label)?.join(', ');
          }
          return <Typography>-</Typography>;
        };
      }

      if (header.field === 'organizations') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          if (params?.row?.organizations?.length > 0) {
            return params?.row?.organizations
              ?.map((organization: ILooseObject) => organization?.ui_label)
              ?.join(', ');
          }
          return <Typography>-</Typography>;
        };
      }
      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

const updateRoleTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header.field === 'role_name') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          if (params?.row?.ui_label) {
            return params?.row?.ui_label;
          }
          return <Typography>-</Typography>;
        };
      } else if (header.field === 'permissions') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          if (params?.row?.permissions?.length > 0) {
            return params?.row?.permissions
              ?.map((permission: ILooseObject) => permission?.ui_label)
              ?.join(', ');
          }
          return <Typography>-</Typography>;
        };
      }
      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

const updateDefaultColumns = (): GridColDef[] => {
  const defaultColumns: GridColDef[] = [];
  defaultColumns.push({
    field: 'Actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const navigation = UseNavigation();
      const orgKeys = params?.row?.organizations?.[0]?.organization_key;
      //when support multiple organization make sure to update this
      return (
        <Box component="div" sx={{ my: 1 }}>
          <Button
            variant="text"
            size="small"
            endIcon={<NavigateNextIcon />}
            color={'primary'}
            sx={{ textTransform: 'none' }}
            onClick={() =>
              navigation.navigateTo(
                `/users/user-details?userKey=${params?.row?.user_key}&orgKey=${orgKeys}`
              )
            }>
            View details
          </Button>
        </Box>
      );
    }
  });
  return defaultColumns;
};

const processApplicationData = (data: ILooseObject) => {
  const applicationLabelCollection = data?.organizations?.reduce(
    (labelCollections: ILooseObject, organization: ILooseObject) => {
      if (organization?.applications && Array.isArray(organization?.applications)) {
        organization?.applications?.forEach((application: ILooseObject) => {
          labelCollections[application?.application_key] = {
            ui_label: application?.ui_label ?? '-'
          };
        });
      }
      return labelCollections;
    },
    {}
  );
  return data?.roles?.map((role: ILooseObject) => {
    return {
      ...role,
      application_label: applicationLabelCollection[role?.application_key]?.ui_label ?? '-'
    };
  });
};

abstract class AllUsersService {
  public static async getAllUsersList(params: IGetAllUsersListParams) {
    const { filters, pagination } = params;
    const result: IGetAllUsersResult = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      },
      page: {
        page: 0,
        size: 10
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.GET_ALL_USERS_LIST,
          filters,
          pagination
        }),
        method: 'GET'
      });

      if (response.data) {
        const tableColumns: GridColDef[] = updateTableColumns(
          TableHeadersConfig.ALL_USERS_LIST || []
        );
        const userPermissions = store.getState()?.userDetails?.permissions ?? [];
        const defaultColumns: GridColDef[] =
          userPermissions?.includes('all_user_view') || userPermissions?.includes('all_role_view')
            ? updateDefaultColumns()
            : [];
        result.columns = [...tableColumns, ...defaultColumns];
        result.rows = response.data?.data ?? [];
        result.page = response.data?.page ?? {};
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getAllUserTypeList() {
    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.GET_ALL_USER_TYPE_LIST
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = response.data?.data ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async addNewUser({ data }: ILooseObject) {
    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.POST_ADD_NEW_USER
        }),
        body: data,
        method: 'POST'
      });

      if (response.data) {
        result.data = response.data?.data ?? {};
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getUserDetailByUserKey(params: IGetUserDetailByUserKeyParams) {
    const { userKey } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.GET_USER_DETAIL_BY_USER_KEY,
          data: {
            userKey
          }
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = response.data?.data ?? {};
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getApplicationsListByOrganization(
    params: IGetApplicationsListByOrganizationParams
  ) {
    const { organizationKey } = params;

    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.GET_ALL_APPLICATIONS_BY_ORGANIZATION,
          data: { organizationKey }
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = response.data?.data ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getRolesListByApplications(params: IGetRolesListByApplicationParams) {
    const { applicationKeys } = params;
    const result: IGetRolesByPermissionResult = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      }
    };

    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.GET_ROLES_BY_APPLICATIONS,
          filters: { application_keys: applicationKeys?.join(',') }
        }),
        method: 'GET'
      });

      if (response.data) {
        const processedData = processApplicationData(response.data?.data);
        const tableColumns: GridColDef[] = updateRoleTableColumns(
          TableHeadersConfig.USER_ROLE_LISTING
        );

        result.columns = [...tableColumns];
        result.rows = processedData ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async deleteUserByUserKey(params: IDeleteUserByUserKeyParams) {
    const { userKey } = params;
    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };

    try {
      const response = await ApiClient.makeRequest({
        url: allUsersApiConfig.GetAllUsersApiUrl({
          url: ALL_USERS_API_URLS_ENUM.DELETE_USER_BY_USER_KEY,
          data: { userKey }
        }),
        method: 'DELETE'
      });

      if (response.data) {
        result.data = response.data?.data ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }
}

export default AllUsersService;
