import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, CircularProgress, Grid2, Divider } from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';

import { RootState, store } from '@/src/datastore/store';
import AppConfig from '@/src/config/AppConfig';
import {
  DYNAMIC_INPUT_TYPES_ENUM,
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  ListingTableDataStateType,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import SchemaValidations from '@/src/utils/SchemaValidations';
import lightTheme from '@/src/styles/themes/lightTheme';
import AllUsersService from '@/src/services/all-users/allUsersService';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import DynamicInput from '@/src/components/common/DynamicInput';
import ConfirmDialog from '@/src/components/common/ConfirmDialog';

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState<ILooseObject>({});
  const [defaultUsersValues, setDefaultUsersValues] = useState<ILooseObject>({});
  const [defaultApplicationsValues, setDefaultApplicationsValues] = useState<ILooseObject>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState<boolean>(false);
  const [inputOptions, setInputOptions] = useState<ILooseObject>({}); // contains all the options
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({}); // contains options loading status
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [userUiMode, setUserUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [applicationUiMode, setApplicationUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);
  const [roleSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [roleTableDetails, setRoleTableDetails] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const userKey = searchParams.get('userKey');
  const organizationKey = searchParams.get('orgKey');
  const schema = SchemaValidations.AddUsers(AttributesConfig.USER_DETAILS);

  const {
    control: controlUserDetails,
    handleSubmit: handleSubmitUserDetails,
    formState: {
      errors: errorsUserDetails,
      isSubmitting: isSubmittingUserDetails,
      isDirty: isDirtyUserDetails
    },
    clearErrors: clearErrorsUserDetails,
    getValues: getValuesUserDetails,
    reset: resetUserDetails
  } = useForm({
    defaultValues: defaultUsersValues,
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur'
  });

  // applications and role form
  const {
    control: controlApplications,
    handleSubmit: handleSubmitApplications,
    formState: {
      errors: errorsApplications,
      isDirty: isDirtyApplications,
      isSubmitting: isSubmittingApplications
    },
    clearErrors: clearErrorsApplications,
    getValues: getValuesApplications,
    reset: resetApplications
  } = useForm({
    defaultValues: { applications: [], roles: [] },
    mode: 'onBlur'
  });

  const userOrgPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.orgPermissions
  );

  const getUserPermissionsUserPage = () => {
    if (organizationKey) {
      return organizationKey?.split(',')?.reduce((acc: string[], key: string) => {
        return Array.from(new Set([...acc, ...(userOrgPermissions?.[key] ?? [])]));
      }, []);
    }
    return [];
  };

  const isAuthorized: boolean =
    getUserPermissionsUserPage()?.includes('user_view') ||
    getUserPermissionsUserPage()?.includes('all_role_view');
  const getUserDetailsValues = getValuesUserDetails();
  const getApplicationsValues = getValuesApplications();

  const processDefaultUserData = (userData: ILooseObject, userAttributes: IAttributeDef[]) => {
    const defaultUserValuesCollection = userAttributes?.reduce(
      (collectedValues: ILooseObject, currentAttribute: IAttributeDef) => {
        if (currentAttribute?.name === 'organization_keys') {
          collectedValues.organization_keys = userData?.organizations?.[0]?.organization_key;
          //TODO: When organization field will be multi select support in future then we have to change here also
        } else {
          collectedValues[currentAttribute?.name] = userData?.[currentAttribute?.name];
        }

        return collectedValues;
      },
      {}
    );

    return { ...defaultUserValuesCollection };
  };

  const processDefaultApplicationData = (userData: ILooseObject) => {
    const applications = userData?.organizations?.[0]?.applications?.map(
      (application: ILooseObject) => application?.application_key
    );
    const roles = userData?.roles?.map((role: ILooseObject) => role?.role_key);

    return { applications, roles };
  };

  const getUserDetails = async (userKey: string) => {
    try {
      setIsLoading(true);
      const results = await AllUsersService.getUserDetailByUserKey({ userKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setUserDetails({});
      } else if (!results.error.code) {
        setUserDetails(results?.data);
        const processedUserData = processDefaultUserData(
          results?.data,
          AttributesConfig.USER_DETAILS
        );
        const processedApplications = processDefaultApplicationData(results?.data);

        setDefaultUsersValues(processedUserData);
        resetUserDetails(processedUserData);
        setDefaultApplicationsValues(processedApplications);
        setRowSelectionModel(processedApplications?.roles ?? []);
        resetApplications(processedApplications);
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      setUserDetails({});
    } finally {
      setIsLoading(false);
    }
  };

  const getAllUserTypeList = async (): Promise<IAttributeOptions[]> => {
    try {
      const results = await AllUsersService.getAllUserTypeList();

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.map((user: ILooseObject) => ({
          label: user?.ui_label ?? '-',
          value: user?.name ?? '-'
        }));
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const getAllApplicationsByOrganizations = async (
    organizationKey: string
  ): Promise<IAttributeOptions[]> => {
    try {
      const results = await AllUsersService.getApplicationsListByOrganization({ organizationKey });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.applications?.map(
          (application: ILooseObject) => ({
            label: application?.ui_label ?? '-',
            value: application?.application_key ?? '-'
          })
        );
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const getAllRolesByApplications = async (applicationKeys: string[]) => {
    try {
      setIsLoadingRoles(true);
      const results = await AllUsersService.getRolesListByApplications({
        applicationKeys
      });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setRoleTableDetails({
          rows: [],
          columns: []
        });
      } else if (!results.error.code) {
        setRoleTableDetails(results);
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingRoles(false);
    }
  };

  const handleResetUserDetails = () => {
    resetUserDetails();
    setUserUiMode('VIEW');
  };

  const handleResetApplications = () => {
    resetApplications();
    setApplicationUiMode('VIEW');
  };

  const handleDeleteUser = async (userKey: string) => {
    try {
      setIsLoadingDeleteUser(true);
      const results = await AllUsersService.deleteUserByUserKey({
        userKey
      });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.delete_user, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        navigate('/users');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingDeleteUser(false);
    }
    // TODO: Implement when API is ready
  };

  const handleUpdateUserDetails = (data: ILooseObject) => {
    //TODO: Implement when API is ready
  };

  const handleUpdateApplications = (data: ILooseObject) => {
    //TODO: Implement when API is ready
  };

  useEffect(() => {
    if (getUserPermissionsUserPage()?.includes('user_view') && userKey) {
      getUserDetails(userKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsOptionsLoading((prev) => ({
          ...prev,
          organization_keys: true,
          user_type: true
        }));

        const organizationList: IAttributeDef[] = store
          .getState()
          .userDetails?.organizations?.map((organization: ILooseObject) => ({
            value: organization?.organization_key ?? '-',
            label: organization?.ui_label ?? '-'
          }));
        const userTypeList = await getAllUserTypeList();

        setInputOptions((prev) => ({
          ...prev,
          organization_keys: organizationList,
          user_type: userTypeList
        }));
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } finally {
        setIsOptionsLoading((prev) => ({
          ...prev,
          organization_keys: false,
          user_type: false
        }));
      }
      // Start loading for both
    };
    if (getUserPermissionsUserPage()?.includes('user_view')) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchApplications = async () => {
      if (defaultUsersValues?.organization_keys) {
        try {
          setIsOptionsLoading((prev) => ({
            ...prev,
            applications: true
          }));
          let applicationsValues: IAttributeOptions[] = [];
          applicationsValues = await getAllApplicationsByOrganizations(
            defaultUsersValues?.organization_keys
          );
          setInputOptions((prev) => ({ ...prev, applications: applicationsValues }));
          // Clean selected values that are no longer valid
          const validApplicationKeys = applicationsValues?.map((app) => app.value);
          setSelectedValue((prevSelected) =>
            prevSelected?.filter((selected) => validApplicationKeys?.includes(selected))
          );
        } catch (error) {
          enqueueSnackbar(JSON.stringify(error), {
            variant: 'error',
            autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
          });
        } finally {
          setIsOptionsLoading((prev) => ({
            ...prev,
            applications: false
          }));
        }
      }
    };
    if (getUserPermissionsUserPage()?.includes('organization_view')) {
      fetchApplications();
    }
  }, [defaultUsersValues?.organization_keys]);

  useEffect(() => {
    if (
      getUserPermissionsUserPage()?.includes('all_role_view') &&
      getApplicationsValues?.applications?.length > 0
    ) {
      getAllRolesByApplications(getApplicationsValues?.applications);
    } else {
      setRoleTableDetails({
        rows: [],
        columns: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationsValues?.applications]);

  if (!isAuthorized) {
    return <NoAuthorization />;
  }

  return (
    <Box>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All users', redirectPath: '/users' },
          { key: 2, name: 'User Details' }
        ]}
      />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmitUserDetails(handleUpdateUserDetails)}
          display="flex"
          flexDirection="column"
          rowGap={2}
          mt={2}>
          {getUserPermissionsUserPage()?.includes('user_view') ? (
            <>
              <Box
                border={1}
                borderColor={lightTheme.palette.grey[300]}
                borderRadius={3}
                sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textTransform="none"
                  color="primary.dark"
                  pb={2}
                  textAlign="center">
                  User details
                </Typography>
                <Grid2 container columnSpacing={10}>
                  {AttributesConfig.USER_DETAILS?.map((attribute: IAttributeDef) => {
                    const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
                    const options = attribute?.options ?? inputOptions?.[attribute?.name] ?? [];
                    const inputFieldType: INPUT_TYPES_ENUM =
                      INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];
                    const isDisabled = attribute?.is_disabled || userUiMode === 'VIEW';
                    const isVisible: boolean = !!attribute?.is_visible;
                    if (!isVisible) {
                      return null;
                    }

                    return (
                      <Grid2
                        container
                        size={{ xs: 6 }}
                        key={attribute?.attribute_id}
                        alignItems="center">
                        {/* UI label */}
                        <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                          <Box display="flex" sx={{ textAlign: 'left', pb: 4 }}>
                            <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                          </Box>
                        </Grid2>
                        {/* DynamicInput */}
                        <Grid2 size={{ xs: 8 }}>
                          {isOptionsLoading?.[attribute?.name] ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Controller
                              name={attribute.name as string}
                              control={controlUserDetails}
                              render={({ field: { onChange, onBlur, value, name } }) => (
                                <DynamicInput
                                  type={inputType}
                                  id={attribute?.attribute_id}
                                  label={attribute?.ui_label}
                                  value={value}
                                  hasError={!!(errorsUserDetails?.[name]?.message as string)}
                                  helperText={(errorsUserDetails?.[name]?.message as string) ?? ' '}
                                  options={options}
                                  maxLength={attribute?.max_length}
                                  disabled={isDisabled}
                                  placeholder={attribute?.placeholder}
                                  disableClearable={attribute?.disable_clearable}
                                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    onChange(e);
                                    clearErrorsUserDetails(name);
                                  }}
                                  onBlur={onBlur}
                                  inputType={inputFieldType}
                                />
                              )}
                            />
                          )}
                        </Grid2>
                      </Grid2>
                    );
                  })}
                </Grid2>
              </Box>
              <Box display="flex" columnGap={1} justifyContent="flex-end">
                {userUiMode === 'VIEW' ? (
                  <>
                    {getUserPermissionsUserPage()?.includes('user_delete') && (
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        loading={isSubmittingUserDetails}
                        onClick={() => setIsOpenDeleteDialog(true)}
                        color="error"
                        sx={{ textTransform: 'none' }}>
                        Delete user
                      </LoadingButton>
                    )}
                    {/* TODO: update the permission while integrating the edit flow */}
                    {getUserPermissionsUserPage()?.includes('') && (
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        onClick={() => setUserUiMode('EDIT')}
                        loading={isSubmittingUserDetails}
                        disabled //TODO: Remove it when update API is ready
                        sx={{ textTransform: 'none' }}>
                        Edit user
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  <>
                    {/* TODO: update the permission while integrating the edit flow */}
                    {getUserPermissionsUserPage()?.includes('') && (
                      <>
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={handleResetUserDetails}>
                          Cancel
                        </LoadingButton>
                        <LoadingButton
                          size="small"
                          variant="contained"
                          type="submit"
                          loading={isSubmittingUserDetails}
                          disabled={
                            Object.keys(errorsUserDetails).length > 0 || !isDirtyUserDetails
                          }
                          sx={{ textTransform: 'none' }}>
                          Save
                        </LoadingButton>
                      </>
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <NoAuthorization />
          )}
          <Box
            border={1}
            borderColor={lightTheme.palette.grey[300]}
            borderRadius={3}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              textTransform="none"
              color="primary.dark"
              pb={2}
              textAlign="center">
              User&#39;s applications and roles
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmitApplications(handleUpdateApplications)}
              border={1}
              borderColor={lightTheme.palette.grey[300]}
              borderRadius={3}
              sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1.5 }}>
              {getUserPermissionsUserPage()?.includes('all_role_view') ? (
                <>
                  <Typography>Applications</Typography>
                  <Controller
                    name="applications"
                    control={controlApplications}
                    render={({ field: { onChange, onBlur, value, name } }) => {
                      return (
                        <DynamicInput
                          type={DYNAMIC_INPUT_TYPES_ENUM.DYNAMIC_MULTI_CHIPS_SELECT}
                          id={12}
                          label={'label'}
                          value={value}
                          required={true}
                          hasError={!!(errorsApplications?.[name]?.message as string)}
                          helperText={(errorsApplications?.[name]?.message as string) ?? ' '}
                          options={inputOptions?.applications}
                          disabled={applicationUiMode === 'VIEW'}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            onChange(e);
                            clearErrorsApplications(name);
                          }}
                          onBlur={onBlur}
                          inputType={INPUT_TYPES_ENUM.TEXT}
                        />
                      );
                    }}
                  />
                  <Divider />
                  <Typography>Roles</Typography>
                  <Controller
                    name="roles"
                    control={controlApplications}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DataGrid
                          getRowId={(row) => row.role_key}
                          rows={
                            applicationUiMode === 'EDIT'
                              ? roleTableDetails.rows
                              : roleTableDetails.rows?.filter((row) =>
                                  defaultApplicationsValues?.roles?.includes(row.role_key)
                                )
                          }
                          columns={roleTableDetails.columns}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableColumnMenu
                          disableDensitySelector
                          unstable_rowSpanning
                          getRowHeight={() => 'auto'}
                          {...(applicationUiMode === 'EDIT' && {
                            checkboxSelection: true,
                            rowSelectionModel: value,
                            onRowSelectionModelChange: (newRowSelectionModel) => {
                              setRowSelectionModel(newRowSelectionModel);
                              onChange(newRowSelectionModel);
                            }
                          })}
                          sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                              textOverflow: 'clip',
                              whiteSpace: 'break-spaces',
                              lineHeight: 1.15,
                              fontWeight: 600
                            },
                            '& .MuiDataGrid-row': {
                              minHeight: '52px !important'
                            },
                            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            '& .MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                              outline: 'none'
                            },
                            '& .MuiDataGrid-main': {
                              overflow: 'unset'
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              position: 'sticky',
                              top: 63,
                              zIndex: 1
                            },
                            '& .MuiDataGrid-container--top': {
                              zIndex: 1
                            },
                            '& .MuiDataGrid-columnHeader': {
                              backgroundColor: lightTheme.palette.grey[100]
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {/* TODO: update the permission while integrating with edit API */}
                  {getUserPermissionsUserPage()?.includes('all_role_view') && (
                    <Box display="flex" columnGap={1} justifyContent="flex-end">
                      {applicationUiMode === 'VIEW' ? (
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          onClick={() => setApplicationUiMode('EDIT')}
                          disabled //TODO: Remove it when update API is ready
                          sx={{ textTransform: 'none' }}>
                          Edit applications
                        </LoadingButton>
                      ) : (
                        <>
                          <LoadingButton
                            size="small"
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                            onClick={handleResetApplications}>
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            size="small"
                            variant="contained"
                            type="submit"
                            loading={isSubmittingApplications}
                            disabled={
                              Object.keys(errorsApplications).length > 0 || !isDirtyApplications
                            }
                            sx={{ textTransform: 'none' }}>
                            Save
                          </LoadingButton>
                        </>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <NoAuthorization />
              )}
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmDialog
        content={`Are you sure you want to delete ${userDetails?.name} ?`}
        handleCloseAction={() => setIsOpenDeleteDialog(false)}
        handleSaveAction={() => handleDeleteUser(userDetails?.user_key)}
        open={isOpenDeleteDialog}
        isLoadingSave={isLoadingDeleteUser}
        saveActionName="Confirm"
      />
    </Box>
  );
};

export default UserDetails;
