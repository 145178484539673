import { enqueueSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ApiClient from '@/src/utils/ApiClient';
import UseNavigation from '@/src/hooks/useNavigation';
import { IApiClientResponse, ILooseObject, IPagination } from '@/src/types/common.interface';
import AllRolesApiConfig, { ALL_ROLES_API_URLS_ENUM } from '@/src/config/api_config/allRoles';
import { store } from '@/src/datastore/store';
import AppConfig from '@/src/config/AppConfig';
import TableHeadersConfig from '@/src/config/TableHeadersConfig';

export interface IGetAllRolesResult {
  rows: ILooseObject[];
  columns: GridColDef[];
  page: IPagination & ILooseObject;
  error: IApiClientResponse['error'];
}

export interface IGetAllRolesListParams {
  filters: ILooseObject;
  pagination: IPagination;
}

export interface IGetRoleDetailsParams {
  roleKey: string;
}

export interface IGetAllUsersByRoleParams {
  roleKey: string;
}

export interface IGetAllUsersByRoleKey {
  rows: ILooseObject[];
  columns: GridColDef[];
  page: IPagination & ILooseObject;
  error: IApiClientResponse['error'];
}

const processApplicationData = (data: ILooseObject) => {
  const applicationLabelCollection = data?.organizations?.reduce(
    (labelCollections: ILooseObject, organization: ILooseObject) => {
      if (organization?.applications && Array.isArray(organization?.applications)) {
        organization?.applications?.forEach((application: ILooseObject) => {
          labelCollections[application?.application_key] = {
            ui_label: application?.ui_label ?? '-'
          };
        });
      }
      return labelCollections;
    },
    {}
  );
  return data?.roles?.map((role: ILooseObject) => {
    return {
      ...role,
      application_label: applicationLabelCollection[role?.application_key]?.ui_label ?? '-'
    };
  });
};

const updateTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers?.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header?.field === 'permissions') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (Array.isArray(row?.permissions) && row?.permissions?.length > 0) {
            return row.permissions
              .map((permission: ILooseObject) => permission?.ui_label ?? '-')
              ?.join(', ');
          }
          return '-'; // Fallback for rows with no permissions
        };
      }

      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

const updateDefaultColumns = (): GridColDef[] => {
  const defaultColumns: GridColDef[] = [];
  //TODO: Implement with authentication
  defaultColumns.push({
    field: 'Actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const navigation = UseNavigation();
      return (
        <Box component="div" sx={{ my: 1 }}>
          <Button
            variant="text"
            size="small"
            endIcon={<NavigateNextIcon />}
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={() =>
              navigation.navigateTo(
                `/roles/role-details?roleKey=${params?.row?.role_key}&applicationKey=${params?.row?.application_key}`
              )
            }>
            View details
          </Button>
        </Box>
      );
    }
  });
  return defaultColumns;
};

const updateUsersRolesTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers?.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header?.field === 'organization') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (Array.isArray(row?.organizations) && row?.organizations?.length > 0) {
            return row.organizations.map((org: ILooseObject) => org?.ui_label).join(', ');
          }
          return '-'; // Fallback for rows with no organization
        };
      }
      if (header?.field === 'bu_tagging') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (Array.isArray(row?.business_units) && row?.business_units?.length > 0) {
            return row.business_units?.map((bu: ILooseObject) => bu?.ui_label).join(', ');
          }
          return '-'; // Fallback for rows with no bu_tagging
        };
      }

      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

abstract class AllRolesService {
  public static async getAllRolesList(params: IGetAllRolesListParams) {
    const { filters, pagination } = params;
    const result: IGetAllRolesResult = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      },
      page: {
        page: 0,
        size: 10
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllRolesApiConfig.GetAllRolesApiUrl({
          url: ALL_ROLES_API_URLS_ENUM.GET_ALL_ROLES_LIST,
          filters,
          pagination
        }),
        method: 'GET'
      });

      if (response.data) {
        const userAppPermissions: ILooseObject =
          store.getState()?.userDetails?.OptionsAndPermissions?.appPermissions;

        const getAppPermissions = () => {
          return Object.values(userAppPermissions)?.reduce(
            (acc: string[], permissions: string[]) => {
              return Array.from(new Set([...acc, ...permissions]));
            },
            []
          );
        };

        const defaultColumns: GridColDef[] =
          getAppPermissions()?.includes('permission_view') ||
          getAppPermissions()?.includes('role_users_view')
            ? updateDefaultColumns()
            : [];
        const updatedColumns: GridColDef[] = updateTableColumns(TableHeadersConfig.ALL_ROLES_LIST);
        result.columns = [...updatedColumns, ...defaultColumns];
        const processedData = processApplicationData(response.data?.data);
        result.rows = processedData ?? [];
        result.page = response.data?.page ?? {};
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getRoleDetails(params: IGetRoleDetailsParams) {
    const { roleKey } = params;
    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllRolesApiConfig.GetAllRolesApiUrl({
          url: ALL_ROLES_API_URLS_ENUM.GET_ROLES_DETAILS,
          data: { roleKey }
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = {
          ...response.data?.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getAllApplications() {
    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllRolesApiConfig.GetAllRolesApiUrl({
          url: ALL_ROLES_API_URLS_ENUM.GET_ALL_APPLICATIONS
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = [...(response.data?.data ?? [])];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getAllPermissions() {
    const result: IApiClientResponse = {
      data: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllRolesApiConfig.GetAllRolesApiUrl({
          url: ALL_ROLES_API_URLS_ENUM.GET_ALL_PERMISSIONS
        }),
        method: 'GET'
      });

      if (response.data) {
        result.data = [...(response.data?.data ?? [])];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async getAllUsersByRole(params: IGetAllUsersByRoleParams) {
    const { roleKey } = params;

    const result: IGetAllUsersByRoleKey = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      },
      page: {
        page: 0,
        size: 10
      }
    };

    try {
      const response = await ApiClient.makeRequest({
        url: AllRolesApiConfig.GetAllRolesApiUrl({
          url: ALL_ROLES_API_URLS_ENUM.GET_ALL_USERS_BY_ROLE,
          data: { roleKey }
        }),
        method: 'GET'
      });

      if (response.data) {
        const updatedColumns: GridColDef[] = updateUsersRolesTableColumns(
          TableHeadersConfig.ALL_USERS_BY_ROLE
        );
        result.columns = [...updatedColumns];
        result.rows = response?.data?.data ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }
}

export default AllRolesService;
