/* eslint-disable no-unused-vars */
import { IGetApiRequestParams, ILooseObject } from '@/src/types/common.interface';
import AppUtils from '@/src/utils/AppUtils';

export enum ALL_API_CONFIG_URLS_ENUM {
  GET_ALL_API_CONFIG_LIST = 'GET_ALL_API_CONFIG_LIST'
}

type GET_API_CONFIG_API_URL_TYPE = IGetApiRequestParams & {
  url: ALL_API_CONFIG_URLS_ENUM;
  data?: ILooseObject;
};

abstract class AllApiConfig {
  public static GetAllUsersApiUrl({
    url,
    data,
    filters,
    pagination,
    sorting
  }: GET_API_CONFIG_API_URL_TYPE) {
    switch (url) {
      case ALL_API_CONFIG_URLS_ENUM.GET_ALL_API_CONFIG_LIST:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/application/${data?.applicationKey}/api_configs`,
          { pagination, sorting, filters }
        );
    }
  }
}

export default AllApiConfig;
