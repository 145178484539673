import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Grid2, Typography } from '@mui/material';

import { RootState, store } from '@/src/datastore/store';
import AllUsersService from '@/src/services/all-users/allUsersService';
import UseNavigation from '@/src/hooks/useNavigation';
import SaveAndCancel from '@/src/components/common/SaveAndCancel';
import DynamicInput from '@/src/components/common/DynamicInput';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import lightTheme from '@/src/styles/themes/lightTheme';
import {
  IApiClientResponse,
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import SchemaValidations from '@/src/utils/SchemaValidations';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppConfig from '@/src/config/AppConfig';
import AppMessages from '@/src/config/message_config/AppMessages';

const AddUser = () => {
  const [inputOptions, setInputOptions] = useState<ILooseObject>({}); // contains all the options
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({}); // contains options loading status

  const navigate = UseNavigation();

  const schema = SchemaValidations.AddUsers(AttributesConfig.ADD_USER_INPUT);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    clearErrors,
    reset
  } = useForm({
    defaultValues: AttributesConfig.ADD_USER_INPUT?.reduce(
      (acc: ILooseObject, attribute: IAttributeDef) => {
        acc[attribute?.name] = '';
        return acc;
      },
      {}
    ),
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const userOrgPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.orgPermissions
  );

  const getUserPermissionsUserPage = () => {
    return Object.values(userOrgPermissions)?.reduce((acc: string[], permissions: string[]) => {
      return Array.from(new Set([...acc, ...permissions]));
    }, []);
  };

  const getAllOrganizationList = async () => {
    // this will return authorized organizations
    try {
      const collectionAuthorizedOrg = Object.entries(
        store.getState()?.userDetails?.OptionsAndPermissions?.orgPermissions as Record<
          string,
          string[]
        >
      )?.reduce((acc: string[], [key, value]: [string, string[]]) => {
        if (value?.includes('all_user_view')) {
          acc = [...acc, key];
        }
        return acc;
      }, []);

      const collectedOptions = Object.entries(
        store.getState()?.userDetails?.OptionsAndPermissions?.organizationsOptions as Record<
          string,
          IAttributeOptions
        >
      )?.reduce((acc: IAttributeOptions[], [key, value]: [string, IAttributeOptions]) => {
        if (collectionAuthorizedOrg?.includes(key)) {
          acc.push(value);
        }
        return acc;
      }, []);

      return collectedOptions;
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const getAllUserTypeList = async (): Promise<IAttributeOptions[]> => {
    try {
      const results = await AllUsersService.getAllUserTypeList();

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.map((user: ILooseObject) => ({
          label: user?.ui_label,
          value: user?.name
        }));
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const processPayloadData = (data: ILooseObject) => {
    const currentData = { ...data };
    currentData.organization_keys = [currentData?.organization_keys];
    return currentData;
  };

  const handleSave = async (data: ILooseObject) => {
    const processedData: ILooseObject = processPayloadData(data);

    try {
      const results: IApiClientResponse = await AllUsersService.addNewUser({
        data: processedData
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.user_added, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        navigate.navigateTo('/users');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Start loading for both
      setIsOptionsLoading((prev) => ({
        ...prev,
        organization_keys: true,
        user_type: true
      }));
      const organizationList = await getAllOrganizationList();
      const userTypeList = await getAllUserTypeList();
      setIsOptionsLoading((prev) => ({
        ...prev,
        organization_keys: false,
        user_type: false
      }));
      setInputOptions({
        ...inputOptions,
        organization_keys: organizationList,
        user_type: userTypeList
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!getUserPermissionsUserPage()?.includes('user_create')) {
    return <NoAuthorization />;
  }

  return (
    <>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All users', redirectPath: '/users' },
          { key: 2, name: 'Add user' }
        ]}
      />

      <Typography variant="h5" sx={{ my: 2 }} color="primary.dark">
        New user
      </Typography>
      <Box component="form" onSubmit={handleSubmit(handleSave)}>
        <Box
          border={1}
          borderColor={lightTheme.palette.grey[300]}
          borderRadius={3}
          sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Grid2 container columnSpacing={10}>
            {AttributesConfig.ADD_USER_INPUT?.map((attribute: IAttributeDef) => {
              const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];

              const options = attribute?.options ?? inputOptions[attribute?.name] ?? [];

              const inputFieldType: INPUT_TYPES_ENUM =
                INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];

              const isVisible: boolean = !!attribute?.is_visible;
              if (!isVisible) {
                return null;
              }

              return (
                <Grid2 container size={{ xs: 6 }} key={attribute?.attribute_id} alignItems="center">
                  {/* UI label */}
                  <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                    <Box sx={{ textAlign: 'left', pb: 4 }}>
                      <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                    </Box>
                  </Grid2>
                  {/* DynamicInput */}
                  <Grid2 size={{ xs: 8 }}>
                    {isOptionsLoading?.[attribute?.name] ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} />
                      </Box>
                    ) : (
                      <Controller
                        name={attribute.name as string}
                        control={control}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <DynamicInput
                            type={inputType}
                            id={attribute?.attribute_id}
                            label={attribute?.ui_label}
                            value={value}
                            hasError={!!(errors?.[name]?.message as string)}
                            helperText={(errors?.[name]?.message as string) ?? ' '}
                            options={options}
                            maxLength={attribute?.max_length}
                            placeholder={attribute?.placeholder}
                            disableClearable={attribute?.disable_clearable}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                              onChange(e);
                              clearErrors(name);
                            }}
                            onBlur={onBlur}
                            inputType={inputFieldType}
                          />
                        )}
                      />
                    )}
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
        {getUserPermissionsUserPage()?.includes('user_create') && (
          <SaveAndCancel
            handleCancel={() => {
              reset();
            }}
            isLoading={isSubmitting}
            disableSave={isSubmitting || Object.keys(errors).length > 0 || !isDirty}
            disableReset={!isDirty}
          />
        )}
      </Box>
    </>
  );
};

export default AddUser;
