import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { IBreadCrumbsDef } from '@/src/types/common.interface';

type DynamicBreadCrumbsProps = {
  breadCrumbsDetails: IBreadCrumbsDef[];
};

const DynamicBreadCrumbs = (props: DynamicBreadCrumbsProps) => {
  const { breadCrumbsDetails } = props;

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadCrumbsDetails?.map((breadCrumb: IBreadCrumbsDef) => {
        return (
          <Box key={breadCrumb?.key}>
            {breadCrumb?.redirectPath && breadCrumb?.redirectPath?.length > 0 ? (
              <Link
                underline="hover"
                key={breadCrumb?.key}
                color="inherit"
                href={breadCrumb?.redirectPath}>
                {breadCrumb?.name}
              </Link>
            ) : (
              <Typography key={breadCrumb?.key} color="inherit">
                {breadCrumb?.name}
              </Typography>
            )}
          </Box>
        );
      })}
    </Breadcrumbs>
  );
};

export default DynamicBreadCrumbs;
