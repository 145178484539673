import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILooseObject } from '@/src/types/common.interface';

const initialState: ILooseObject = {};

const userDetails = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    saveUserData: (state, action: PayloadAction<ILooseObject>) => {
      return { ...state, ...action.payload };
    },
    clearUserData: () => {
      return {}; // Reset state to an empty object
    }
  }
});

export const { saveUserData, clearUserData } = userDetails.actions;
export default userDetails.reducer;
