import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Box, CircularProgress } from '@mui/material';

import { saveUserData } from '@/src/datastore/userDetails';
import UserLoginService from '@/src/services/common/userLoginService';
import LocalStorage from '@/src/utils/LocalStorage';
import AppConfig from '@/src/config/AppConfig';
import { IApiClientResponse, ILooseObject } from '@/src/types/common.interface';

const OAuth2Redirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const processUserData = (userData: ILooseObject) => {
    const organizations = userData?.organizations?.reduce(
      (acc: ILooseObject, org: ILooseObject) => {
        acc[org?.organization_key] = {
          label: org?.ui_label ?? '-',
          value: org?.organization_key ?? '-'
        };
        return acc;
      },
      {}
    );

    // Process applications
    const applications = userData?.organizations?.reduce((acc: ILooseObject, org: ILooseObject) => {
      org?.applications?.forEach((app: ILooseObject) => {
        acc[app?.application_key] = {
          label: app?.ui_label ?? '-',
          value: app?.application_key ?? '-'
        };
      });
      return acc;
    }, {});

    const permissions: ILooseObject = userData?.roles?.reduce(
      (acc: ILooseObject, item: ILooseObject) => {
        const permissions = item?.permissions?.map((perm: ILooseObject) => perm?.name);
        if (item?.organization_key) {
          const updatedPermissions = Array.from(
            new Set([...(acc.orgPermissions?.[item.organization_key] ?? []), ...permissions])
          );
          acc.orgPermissions[item.organization_key] = updatedPermissions;
        }

        if (item?.application_key) {
          const updatedPermissions = Array.from(
            new Set([...(acc.appPermissions?.[item.application_key] ?? []), ...permissions])
          );
          acc.appPermissions[item.application_key] = updatedPermissions;
        }

        return acc;
      },
      { orgPermissions: {}, appPermissions: {} }
    );
    return {
      organizationsOptions: organizations,
      applicationsOptions: applications,
      ...permissions
    };
  };

  const getUserData = useCallback(
    async (token: string) => {
      const results: IApiClientResponse = await UserLoginService.getUserData({
        token
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        navigate('/login');
      } else {
        // saving userData in the redux
        const userPermissionsAndOptions = processUserData(results?.data);
        const userPermissions: string[] = [];
        results?.data?.roles
          ?.filter(
            (role: ILooseObject) =>
              (role?.application_key &&
                role?.application_key === process.env.REACT_APP_APPLICATION_KEY) ||
              !!role?.organization_key
          )
          ?.forEach((role: ILooseObject) => {
            role?.permissions?.forEach(
              (permission: ILooseObject) =>
                permission?.name && userPermissions.push(permission?.name)
            );
          });

        dispatch(
          saveUserData({
            ...results?.data,
            OptionsAndPermissions: userPermissionsAndOptions,
            permissions: userPermissions
          })
        );
        const redirectTo: string = location.pathname + location.search;
        //TODO: redirect on the existing path
        navigate('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, token]
  );

  useEffect(() => {
    if (token) {
      getUserData(token as string);
    } else {
      const accessToken = LocalStorage.getItem('token');
      if (accessToken) {
        getUserData(accessToken);
        LocalStorage.removeItem('token');
      }
    }
  }, [getUserData, token]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      {/* TODO: Make the correct loading */}
      <CircularProgress />
    </Box>
  );
};

export default OAuth2Redirect;
