import { KeyboardEvent } from 'react';
import { useLocation } from 'react-router';

import { store } from '@/src/datastore/store';
import UseNavigation from '@/src/hooks/useNavigation';
import {
  DYNAMIC_INPUT_TYPES_ENUM,
  IGetApiRequestParams,
  ILooseObject,
  ISorting
} from '@/src/types/common.interface';

export interface IApiQueryParams extends ILooseObject {
  page?: string;
  size?: string;
  sort?: string;
}

const convertValues = (value: any) => {
  switch (typeof value) {
    case 'boolean':
    case 'string':
      return value;
    case 'number':
      return value?.toString();
    case 'object':
      if (Array.isArray(value)) {
        return value?.join(',');
      } else {
        return value;
      }
    default:
      return;
  }
};

abstract class AppUtils {
  public static redirectToLogin() {
    const router = UseNavigation();
    const currentPath = useLocation();
    router.navigateTo(`/login?redirectTo=${encodeURIComponent(currentPath.pathname)}`);
  }

  public static formatGetApiParameters(
    rawApiUrl: string,
    { pagination, sorting, filters }: IGetApiRequestParams
  ) {
    const apiUrl: URL = new URL(
      rawApiUrl,
      rawApiUrl.startsWith('http') ? undefined : window.location.origin
    );
    const queryParams: IApiQueryParams = {};

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        queryParams[key] = convertValues(value);
      }
    }

    if (pagination) {
      queryParams.page = pagination.page?.toString();
      queryParams.size = pagination.size?.toString();
    }

    if (sorting && sorting.length > 0) {
      const sortingAttributes: string[] = sorting.map((sortAttribute: ISorting) => {
        return `${sortAttribute.field}:${sortAttribute.order}`;
      });
      queryParams.sort = sortingAttributes.join(',');
    }
    return Object.keys(queryParams).length
      ? `${apiUrl}?${new URLSearchParams(queryParams as Record<string, string>).toString()}`
      : apiUrl.toString();
  }

  public static blockInvalidInputChar(
    type: DYNAMIC_INPUT_TYPES_ENUM,
    event: KeyboardEvent<HTMLElement>,
    allowNegative?: boolean
  ): void {
    const invalidChars: Partial<Record<DYNAMIC_INPUT_TYPES_ENUM, string[]>> = {
      [DYNAMIC_INPUT_TYPES_ENUM.INTEGER]: ['e', 'E', '+', ...(allowNegative ? [] : ['-']), '.']
    };

    const charsToBlock = invalidChars[type] || [];
    if (charsToBlock?.includes(event.key)) {
      event.preventDefault();
    }
  }

  public static getUserData(): ILooseObject {
    const userData = store.getState()?.userDetails;
    return userData;
  }
}

export default AppUtils;
