/* eslint-disable no-unused-vars */
import { IGetApiRequestParams, ILooseObject } from '@/src/types/common.interface';
import AppUtils from '@/src/utils/AppUtils';

export enum ALL_ROLES_API_URLS_ENUM {
  GET_ALL_ROLES_LIST = 'GET_ALL_ROLES_LIST',
  GET_ROLES_DETAILS = 'GET_ROLES_DETAILS',
  GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS',
  GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS',
  GET_ALL_USERS_BY_ROLE = 'GET_ALL_USERS_BY_ROLE'
}

type GET_ALL_ROLES_API_URL_TYPE = IGetApiRequestParams & {
  url: ALL_ROLES_API_URLS_ENUM;
  data?: ILooseObject;
};

abstract class AllRolesApiConfig {
  public static GetAllRolesApiUrl({ url, filters, pagination, data }: GET_ALL_ROLES_API_URL_TYPE) {
    switch (url) {
      case ALL_ROLES_API_URLS_ENUM.GET_ALL_ROLES_LIST:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/roles`,
          { pagination, filters }
        );

      case ALL_ROLES_API_URLS_ENUM.GET_ROLES_DETAILS:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/roles/${data?.roleKey}`;

      case ALL_ROLES_API_URLS_ENUM.GET_ALL_APPLICATIONS:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/applications`;

      case ALL_ROLES_API_URLS_ENUM.GET_ALL_PERMISSIONS:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/permissions`;

      case ALL_ROLES_API_URLS_ENUM.GET_ALL_USERS_BY_ROLE:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/users/${data?.roleKey}`;
    }
  }
}

export default AllRolesApiConfig;
