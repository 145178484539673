import '@/src/styles/global.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import { RootState } from '@/src/datastore/store';
import AuthMiddleware from '@/src/middleware/AuthMiddleware';
import NotFound from '@/src/components/common/NotFound';
import NotistackCloseButton from '@/src/components/common/NotistackCloseButton';
import Layout from '@/src/components/common/Layout';
import LoginPage from '@/src/pages/login/LoginPage';
import AddUser from '@/src/pages/users/add-user';
import Users from '@/src/pages/users';
import OAuth2Redirect from '@/src/pages/oauth2/redirect';
import Roles from '@/src/pages/roles';
import ApiConfig from '@/src/pages/api-config';
import UserDetails from '@/src/pages/users/user-details';
import RoleDetails from '@/src/pages/roles/role-details';
import lightTheme from '@/src/styles/themes/lightTheme';
import { ILooseObject } from '@/src/types/common.interface';

const App = () => {
  const handleCloseSnackbar: React.FC<SnackbarKey> = (snackbarKey) => {
    return <NotistackCloseButton snackbarKey={snackbarKey} />;
  };

  const userOrgPermissions: ILooseObject =
    useSelector((state: RootState) => state?.userDetails?.OptionsAndPermissions?.orgPermissions) ??
    {};

  const getUserPermissions = () => {
    return Object.values(userOrgPermissions)?.length > 0
      ? Object.values(userOrgPermissions)?.reduce((acc: string[], permissions: string[]) => {
          return Array.from(new Set([...acc, ...permissions]));
        }, [])
      : [];
  };

  const userAppPermissions: ILooseObject =
    useSelector((state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions) ??
    [];

  const getAppPermissions = () => {
    return Object.values(userAppPermissions)?.length > 0
      ? Object.values(userAppPermissions)?.reduce((acc: string[], permissions: string[]) => {
          return Array.from(new Set([...acc, ...permissions]));
        }, [])
      : [];
  };

  const getHomePageRedirectUrl = (): string => {
    if (getUserPermissions()?.includes('all_user_view')) {
      return '/users';
    } else if (getAppPermissions()?.includes('all_role_view')) {
      return '/roles';
    } else if (getAppPermissions()?.includes('api_config_view')) {
      return '/api-config';
    }
    return '/login';
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        action={handleCloseSnackbar}>
        <Router>
          <Routes>
            <Route element={<AuthMiddleware />}>
              <Route element={<Layout />}>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Navigate to={getHomePageRedirectUrl()} replace />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/user-details" element={<UserDetails />} />
                <Route path="/users/add-user" element={<AddUser />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/api-config" element={<ApiConfig />} />
                <Route path="/roles/role-details" element={<RoleDetails />} />
              </Route>
            </Route>
            <Route path="login" element={<LoginPage />} />
            <Route path="/oauth2/redirect" element={<OAuth2Redirect />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
