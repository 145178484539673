import { enqueueSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import ApiClient from '@/src/utils/ApiClient';
import UseNavigation from '@/src/hooks/useNavigation';
import { IApiClientResponse, ILooseObject } from '@/src/types/common.interface';
import { store } from '@/src/datastore/store';
import AppConfig from '@/src/config/AppConfig';
import TableHeadersConfig from '@/src/config/TableHeadersConfig';
import AllApiConfig, { ALL_API_CONFIG_URLS_ENUM } from '@/src/config/api_config/allApiConfig';

export interface IGetApiConfigListResult {
  rows: ILooseObject[];
  columns: GridColDef[];
  error: IApiClientResponse['error'];
}

export interface IGetApiConfigListParams {
  applicationKey: string;
}

const updateTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header.field === 'permissions') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (Array.isArray(row?.permissions) && row?.permissions?.length > 0) {
            return row.permissions
              .map((permission: ILooseObject) => permission?.ui_label ?? '-')
              ?.join(', ');
          }
          return '-';
        };
      } else if (header.field === 'method') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (row?.method) {
            return row.method.toUpperCase();
          }
          return '-';
        };
      } else if (header.field === 'is_authorized') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          const isAuthorized = params?.row?.is_authorized;
          if (typeof isAuthorized === 'boolean') {
            return (
              <Box component="div" sx={{ my: 1 }}>
                {isAuthorized ? <CheckIcon /> : <CloseIcon />}
              </Box>
            );
          }
          return '-';
        };
      } else if (header.field === 'preview_mode') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          const isPreviewMode = params?.row?.preview_mode;
          if (typeof isPreviewMode === 'boolean') {
            return (
              <Box component="div" sx={{ my: 1 }}>
                {isPreviewMode ? <CheckIcon /> : <CloseIcon />}
              </Box>
            );
          }
          return '-';
        };
      }
      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

const updateDefaultColumns = (): GridColDef[] => {
  const defaultColumns: GridColDef[] = [];
  defaultColumns.push({
    field: 'Actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const navigation = UseNavigation();
      return (
        <Box component="div" sx={{ my: 1 }}>
          <Button
            variant="text"
            size="small"
            endIcon={<NavigateNextIcon />}
            color={'primary'}
            sx={{ textTransform: 'none' }}
            disabled //TODO: make it enable when integrate view details page of api config
            onClick={() =>
              navigation.navigateTo(`/api-config/api-config-details?id=${params?.row?.id}`)
            }>
            View details
          </Button>
        </Box>
      );
    }
  });
  return defaultColumns;
};

abstract class AllApiConfigService {
  public static async getApiConfigList(params: IGetApiConfigListParams) {
    const { applicationKey } = params;
    const result: IGetApiConfigListResult = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetAllUsersApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.GET_ALL_API_CONFIG_LIST,
          data: { applicationKey }
        }),
        method: 'GET'
      });

      if (response.data) {
        const tableColumns: GridColDef[] = updateTableColumns(
          TableHeadersConfig.ALL_API_CONFIG_LIST || []
        );
        const userPermissions = store.getState()?.userDetails?.permissions ?? [];
        const defaultColumns: GridColDef[] =
          userPermissions?.includes('all_user_view') || userPermissions?.includes('api_config_view')
            ? updateDefaultColumns()
            : [];

        result.columns = [...tableColumns, ...defaultColumns];
        result.rows = response.data?.data?.api_configs ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }
}

export default AllApiConfigService;
