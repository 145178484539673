import { ChangeEvent, SyntheticEvent } from 'react';
import {
  Autocomplete,
  AutocompleteValue,
  Box,
  Chip,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import AppUtils from '@/src/utils/AppUtils';
import {
  DYNAMIC_INPUT_TYPES_ENUM,
  IDynamicInputProp,
  IAttributeOptions
} from '@/src/types/common.interface';

const TextInput = (props: IDynamicInputProp) => {
  const {
    id,
    variant = 'outlined',
    inputType,
    disabled,
    size = 'small',
    required,
    value,
    onChange,
    hasError,
    placeholder,
    helperText,
    onBlur,
    sx,
    maxLength
  } = props;

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    if (typeof maxLength === 'number' && newValue.length > maxLength) {
      return;
    }
    onChange?.(newValue);
  };

  return (
    <TextField
      key={id}
      id={`${id}`}
      type={inputType}
      fullWidth
      variant={variant}
      disabled={disabled}
      size={size}
      placeholder={placeholder}
      required={required}
      value={value}
      onChange={handleChangeInput}
      sx={{ mb: 1, minWidth: 120, ...sx }}
      error={hasError}
      helperText={helperText}
      onBlur={onBlur}
      onKeyDown={(event) => AppUtils.blockInvalidInputChar(props.type, event)}
      autoComplete="off"
    />
  );
};

const SelectInput = (props: IDynamicInputProp) => {
  const {
    id,
    sx,
    size = 'small',
    required,
    hasError,
    helperText,
    onBlur,
    disabled,
    placeholder,
    value,
    options = [],
    onChange,
    disableClearable
  } = props;

  const currentOption =
    options.find((option: IAttributeOptions) => option?.value === value) ?? null;

  return (
    <Autocomplete
      key={id}
      id={`${id}`}
      options={options}
      fullWidth
      sx={{ mb: 1, minWidth: 120, ...sx }}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          placeholder={placeholder}
          error={hasError}
          helperText={helperText}
          onBlur={onBlur}
        />
      )}
      disabled={disabled}
      value={currentOption}
      onChange={(
        event: SyntheticEvent,
        selectedOption: AutocompleteValue<any, false, false, false>
      ): void => {
        onChange?.(selectedOption?.value ?? selectedOption ?? '');
      }}
      disableClearable={disableClearable}
    />
  );
};

const DynamicMultiChipsInput = (props: IDynamicInputProp) => {
  const {
    id,
    sx,
    hasError,
    helperText,
    onBlur,
    disabled,
    value = [],
    options = [],
    onChange
  } = props;

  // Filter the valid options from the current value
  const validValues = (value as string[])?.filter((val: string | number) =>
    options?.some((opt: IAttributeOptions) => opt?.value === val)
  );

  // Find available options excluding the current values
  const availableOptions = options?.filter(
    (option: IAttributeOptions) => !validValues?.includes(option?.value as string)
  );

  const handleChangeInput = (option: IAttributeOptions) => {
    const updatedValues = [...validValues, option?.value];
    onChange?.(updatedValues);
  };

  const handleDelete = (option: string | number) => {
    const updatedValues = validValues?.filter((val: string | number) => val !== option);
    onChange?.(updatedValues);
  };

  const getLabelForValue = (val: string) => {
    const option = options?.find((opt: IAttributeOptions) => opt?.value === val);
    return option ? option?.label : val;
  };

  return (
    <Box key={id} display="flex" flexDirection="column" sx={{ rowGap: 2, ...sx }}>
      {/* Selected Options Section */}
      <Box>
        <Stack direction="row" spacing={1} rowGap={1} columnGap={1} flexWrap="wrap">
          {validValues.length > 0 ? (
            validValues.map((option) => (
              <Chip
                key={option}
                label={getLabelForValue(option)}
                onDelete={(e) => {
                  onBlur?.(e);
                  handleDelete(option);
                }}
                color={hasError ? 'error' : 'default'}
                disabled={disabled}
              />
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No options selected
            </Typography>
          )}
        </Stack>
        {helperText && (
          <Typography variant="caption" color="error">
            {helperText}
          </Typography>
        )}
      </Box>

      {/* Available Options Section */}
      {!disabled && (
        <Box>
          <Stack direction="row" spacing={1} rowGap={1} columnGap={1} flexWrap="wrap">
            {availableOptions.length > 0 ? (
              availableOptions.map((option: IAttributeOptions) => (
                <Chip
                  key={option?.value as string}
                  label={option.label}
                  onDelete={(e) => {
                    onBlur?.(e);
                    handleChangeInput(option);
                  }}
                  deleteIcon={<AddIcon />}
                  variant="filled"
                  color="info"
                />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No available options
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

const DynamicInput = (props: IDynamicInputProp): JSX.Element => {
  switch (props.type) {
    case DYNAMIC_INPUT_TYPES_ENUM.TEXT:
    case DYNAMIC_INPUT_TYPES_ENUM.INTEGER:
      return TextInput(props);
    case DYNAMIC_INPUT_TYPES_ENUM.SELECT:
      return SelectInput(props);
    case DYNAMIC_INPUT_TYPES_ENUM.DYNAMIC_MULTI_CHIPS_SELECT:
      return DynamicMultiChipsInput(props);
    default:
      return <></>;
  }
};
export default DynamicInput;
