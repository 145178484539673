import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { IApiClientInput, IApiClientResponse, ILooseObject } from '@/src/types/common.interface';
import AppConfig from '@/src/config/AppConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import { store } from '@/src/datastore/store';

const processError = (error: any, result: IApiClientResponse) => {
  if (error.response?.status === 401) {
    // Only set error code to check if api call failed
    result.error = {
      code: error.response.status,
      message: error?.response?.data?.error?.message ?? AppMessages.Error.unauthorized
    };
    enqueueSnackbar(result.error?.message, {
      variant: 'error',
      autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
    });
  } else if (error.response?.status === 403) {
    // Only set error code to check if api call failed
    result.error = {
      code: error.response.status,
      message: error?.response?.data?.error?.message ?? AppMessages.Error.access_denied
    };
    enqueueSnackbar(result.error?.message, {
      variant: 'error',
      autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
    });
  } else if (typeof error.response?.data?.error?.code === 'number') {
    result.error = {
      code: error.response.data.error.code,
      message: error.response.data.error.message
    };
    result.errors = error.response.data?.errors;
  } else {
    result.error = {
      code: error.response.status,
      message: error.message,
      ...error.response.data
    };
  }
  return result;
};

abstract class ApiClient {
  public static async makeRequest(params: IApiClientInput): Promise<IApiClientResponse> {
    const { url, method, headers, body } = params;

    let result: IApiClientResponse = {} as IApiClientResponse;
    try {
      if (!url) {
        throw new Error(AppMessages.Error.missing_url);
      }
      if (!method) {
        throw new Error(AppMessages.Error.missing_method);
      }

      const options: AxiosRequestConfig = {
        url,
        method,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        }
        // signal,
      };

      const userData: ILooseObject = store.getState()?.userDetails;

      if (userData?.token) {
        options.headers!.Authorization = `Bearer ${userData.token}`;
      }

      if (body && body instanceof FormData) {
        options.data = body;
      } else if (body) {
        options.data = JSON.stringify(body);
      }

      const response = await axios(options);
      result.data = response.data;
      result.headers = response.headers;
    } catch (error: any) {
      if (error.response) {
        result.headers = error.response?.headers;
        // handle 401 & 403
        result = processError(error, result);
      } else if (error.code !== AxiosError.ERR_CANCELED) {
        result.error = {
          code: 500,
          message: error.message
        };
      }
    }
    return result;
  }
}

export default ApiClient;
