import { Alert } from '@mui/material';
import Grid2 from '@mui/material/Grid2';

type NoAuthorizationProps = {
  isUnAuthenticated?: boolean;
};

const NoAuthorization = (props: NoAuthorizationProps) => {
  const { isUnAuthenticated } = props;

  return (
    <Grid2 container spacing={2}>
      <Grid2>
        {isUnAuthenticated ? (
          <Alert severity="error">
            You don&apos;t have access to the <b>Access UAM Portal</b>. Please grant access.
          </Alert>
        ) : (
          <Alert severity="error">You don&apos;t have access to the requested feature.</Alert>
        )}
      </Grid2>
    </Grid2>
  );
};

export default NoAuthorization;
