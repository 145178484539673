import { RootState } from '@/src/datastore/store';
import { Box, Button, Grid2, Typography } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useSelector } from 'react-redux';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import UseNavigation from '@/src/hooks/useNavigation';
import lightTheme from '@/src/styles/themes/lightTheme';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import {
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DynamicInput from '@/src/components/common/DynamicInput';
import { DataGrid } from '@mui/x-data-grid';
import CustomTablePaginationActions from '@/src/components/common/CustomTablePaginationActions';
import { enqueueSnackbar } from 'notistack';
import AppConfig from '@/src/config/AppConfig';
import AllApiConfigService from '@/src/services/api-config/allApiConfigService';

const ApiConfig = () => {
  const [extraFieldConfig, setExtraFieldConfig] = useState<ILooseObject>({});
  const [tableDetails, setTableDetails] = useState<ILooseObject>({
    columns: [],
    rows: []
  });
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  const navigate = UseNavigation();
  const userApplicationsOptions: IAttributeOptions[] = Object.values(
    useSelector((state: RootState) => state?.userDetails?.OptionsAndPermissions)
      ?.applicationsOptions as IAttributeOptions[]
  )?.map((application: IAttributeOptions) => application);

  const {
    control,
    formState: { dirtyFields, errors },
    watch,
    reset,
    clearErrors
  } = useForm<{
    [key: string]: any;
  }>({
    mode: 'onSubmit',
    defaultValues: {}
  });

  const watchedValues: ILooseObject = watch(); // Reactive watch for form values

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = (applicationKey?: string) => {
    //filtering permissions who have users applications
    return Object.entries(userAppPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        if (applicationKey) {
          if (key === applicationKey) {
            return Array.from(new Set([...acc, ...permissions]));
          } else {
            return acc;
          }
        }
        return Array.from(new Set([...acc, ...permissions]));
      },
      []
    );
  };

  const getApiConfigList = async (applicationKey: string) => {
    try {
      setIsLoadingTable(true);
      const results = await AllApiConfigService.getApiConfigList({
        applicationKey
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        setTableDetails({
          columns: results.columns,
          rows: results.rows
        });
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      setTableDetails({
        columns: [],
        rows: []
      });
    } finally {
      setIsLoadingTable(false);
    }
  };

  const getAvailableOptions = (attributeField: ILooseObject, extraFieldConfig?: ILooseObject) => {
    const availableOptions =
      extraFieldConfig?.[attributeField?.name]?.options ?? attributeField?.options ?? [];
    return availableOptions;
  };

  useEffect(() => {
    if (
      watchedValues?.application &&
      getAppPermissions(watchedValues?.application)?.includes('api_config_view')
    ) {
      getApiConfigList(watchedValues?.application);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchedValues)]);

  useEffect(() => {
    if (userApplicationsOptions?.length > 0) {
      setExtraFieldConfig((preValue) => ({
        ...preValue,
        application: { ...preValue?.application, options: userApplicationsOptions }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!getAppPermissions()?.includes('api_config_view')) {
    return <NoAuthorization />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="primary.dark">
          API Config
        </Typography>
        {getAppPermissions()?.includes('api_config_create') && (
          <Button
            variant="outlined"
            startIcon={<PersonAddAltIcon />}
            size="small"
            sx={{ textTransform: 'none' }}
            disabled //TODO: enable when integrate add flow
            onClick={() => {
              navigate.navigateTo('/api-config/add-api-config');
            }}>
            Add API config
          </Button>
        )}
      </Box>
      <Box
        border={1}
        borderColor={lightTheme.palette.grey[300]}
        borderRadius={3}
        sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        <Grid2
          container
          component="form"
          display="flex"
          flexDirection="row"
          columnGap={2}
          alignItems="center"
          justifyContent="space-between"
          width="100%">
          {AttributesConfig.ALL_API_CONFIG_LIST_INPUT?.map((attributeField: IAttributeDef) => {
            const type = UI_TYPE_INPUT_TYPE_MAP[attributeField?.ui_type?.toUpperCase()];
            const inputType: INPUT_TYPES_ENUM =
              INPUT_FIELD_TYPE_MAP[attributeField?.value_type?.toUpperCase()];

            const isRequired: boolean = !!attributeField?.is_mandatory;
            const isVisible: boolean = !!attributeField?.is_visible;
            if (!isVisible) {
              return null;
            }
            const availableOptions = getAvailableOptions(attributeField, extraFieldConfig);

            return (
              <Grid2
                display="flex"
                flexDirection="column"
                rowGap={0.5}
                key={attributeField?.attribute_id}
                size={{ xs: 4 }}>
                <Typography variant="body2" fontWeight={600}>
                  {attributeField?.ui_label}
                </Typography>
                <Controller
                  name={attributeField?.name}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <DynamicInput
                      id={attributeField?.attribute_id}
                      label=""
                      type={type}
                      inputType={inputType}
                      required={isRequired}
                      value={value ?? ''}
                      disabled={false}
                      options={availableOptions}
                      size="small"
                      helperText={(errors[name]?.message as string) ?? ' '}
                      hasError={!!(errors[name]?.message as string)}
                      onChange={onChange}
                      onBlur={onBlur}
                      disableClearable={true}
                    />
                  )}
                />
              </Grid2>
            );
          })}
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              reset();
              clearErrors();
            }}
            disabled={!(dirtyFields && Object.keys(dirtyFields)?.length > 0)}>
            Reset
          </Button>
        </Grid2>
      </Box>
      {Object.keys(dirtyFields)?.length > 0 && (
        <Box
          border={1}
          borderColor={lightTheme.palette.grey[300]}
          borderRadius={3}
          sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            textTransform="none"
            color="primary.dark"
            pb={2}
            textAlign="center">
            API Config List
          </Typography>
          {getAppPermissions(watchedValues?.application)?.includes('api_config_view') ? (
            <DataGrid
              getRowId={(row) => row?.id}
              rows={tableDetails.rows}
              columns={tableDetails.columns}
              disableRowSelectionOnClick
              disableColumnSelector
              disableColumnMenu
              disableDensitySelector
              getRowHeight={() => 'auto'}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: 'clip',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1.15,
                  fontWeight: 600
                },
                '& .MuiDataGrid-row': {
                  minHeight: '52px !important'
                },
                '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none'
                },
                '& .MuiDataGrid-main': {
                  overflow: 'unset'
                },
                '& .MuiDataGrid-columnHeaders': {
                  position: 'sticky',
                  top: 63,
                  zIndex: 1
                },
                '& .MuiDataGrid-container--top': {
                  zIndex: 1
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: lightTheme.palette.grey[100]
                }
              }}
              slotProps={{
                pagination: {
                  ActionsComponent: CustomTablePaginationActions
                }
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 20, page: 0 }
                }
              }}
              loading={isLoadingTable}
              pageSizeOptions={[20, 50, 100]}
            />
          ) : (
            <NoAuthorization />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ApiConfig;
