import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from '@/src/App';
import { persistor, store } from '@/src/datastore/store';

const container = document.getElementById('app-root')!;
const root = createRoot(container);
const isStrictMode = process.env.REACT_APP_APP_ENV === 'LOCAL';

root.render(
  isStrictMode ? (
    <StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>
  ) : (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
);
