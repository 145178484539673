import { useNavigate } from 'react-router-dom';

/**
 * The useNavigation hook in TypeScript React provides a function to navigate to a specified path.
 * @returns An object containing the `navigateTo` function is being returned.
 */
const UseNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const navigateForward = () => {
    navigate(1);
  };

  const navigateReplace = (path: string) => {
    navigate(path, { replace: true });
  };

  const navigatePush = (path: string) => {
    navigate(path, { replace: false });
  };

  return {
    navigateTo,
    navigateBack,
    navigateForward,
    navigateReplace,
    navigatePush
  };
};

export default UseNavigation;
