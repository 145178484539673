import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { RootState } from '@/src/datastore/store';

const AuthMiddleware = () => {
  const userData = useSelector((state: RootState) => state?.userDetails);
  const location = useLocation();
  const navigate = useNavigate(); // Use the navigate hook

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!userData?.token) {
        // If not authenticated, redirect to the login page
        navigate(`/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`);
      }
    };

    checkAuthentication(); // Check authentication on every render
  }, [userData, location, navigate]); // Include navigate in the dependency array

  if (userData?.token) {
    // If authenticated, render the protected route
    return <Outlet />;
  } else {
    // While checking authentication, render nothing to prevent rendering the protected content before the redirect happens
    return null;
  }
};

export default AuthMiddleware;
