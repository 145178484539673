import { MouseEventHandler, ReactNode } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

type AlertDialogProps = {
  handleCloseAction: MouseEventHandler;
  handleSaveAction: MouseEventHandler;
  open: boolean;
  content: ReactNode;
  isLoadingSave?: boolean;
  title?: string;
  closeActionName?: string;
  saveActionName?: string;
};

const ConfirmDialog = (props: AlertDialogProps) => {
  const {
    open,
    handleCloseAction,
    title = 'Confirm the action',
    closeActionName = 'Cancel',
    saveActionName = 'Save',
    content,
    handleSaveAction,
    isLoadingSave
  } = props;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleCloseAction}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleCloseAction}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" color="error" onClick={handleCloseAction}>
          {closeActionName}
        </Button>
        <LoadingButton
          size="small"
          variant="contained"
          color="primary"
          loading={isLoadingSave}
          onClick={handleSaveAction}
          autoFocus>
          {saveActionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
