import { GridColDef } from '@mui/x-data-grid';

export interface ITableHeadersConfig {
  ALL_USERS_LIST: GridColDef[];
  USER_ROLE_LISTING: GridColDef[];
  ALL_ROLES_LIST: GridColDef[];
  ALL_USERS_BY_ROLE: GridColDef[];
  ALL_API_CONFIG_LIST: GridColDef[];
}

const TableHeadersConfig: ITableHeadersConfig = {
  ALL_USERS_LIST: [
    {
      field: 'name',
      headerName: 'User name',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'email_id',
      headerName: 'Email ID',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'mobile',
      headerName: 'Phone number',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'user_key',
      headerName: 'User ID',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'user_type',
      headerName: 'User type',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'organizations',
      headerName: 'Organization',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    },
    {
      field: 'business_units',
      headerName: 'BU tagging',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100
    }
  ],
  USER_ROLE_LISTING: [
    {
      field: 'application_label',
      headerName: 'Application',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'role_name',
      headerName: 'Role name',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 500
    }
  ],
  ALL_ROLES_LIST: [
    {
      field: 'ui_label',
      headerName: 'Role name',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'role_key',
      headerName: 'Role ID',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'application_label',
      headerName: 'Application',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    }
  ],
  ALL_USERS_BY_ROLE: [
    {
      field: 'name',
      headerName: 'User name',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'email_id',
      headerName: 'Email ID',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'mobile',
      headerName: 'Phone number',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'user_key',
      headerName: 'User key',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'user_type',
      headerName: 'User type',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'organization',
      headerName: 'Organization',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'bu_tagging',
      headerName: 'BU tagging',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 150
    }
  ],
  ALL_API_CONFIG_LIST: [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 200
    },
    {
      field: 'method',
      headerName: 'HTTP method',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 80
    },
    {
      field: 'uri',
      headerName: 'URI',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 300
    },
    {
      field: 'version',
      headerName: 'Version',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 50
    },
    {
      field: 'is_authorized',
      headerName: 'Is authorized',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 80
    },
    {
      field: 'preview_mode',
      headerName: 'Preview mode',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 80
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      sortable: false,
      filterable: true,
      flex: 1,
      minWidth: 300
    }
  ]
};

export default TableHeadersConfig;
