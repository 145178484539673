import { ReactNode, useEffect, useState } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { UsersThree, WebhooksLogo } from '@phosphor-icons/react';

import { clearReduxStore, RootState } from '@/src/datastore/store';
import AppConfig, { INavigationMenu } from '@/src/config/AppConfig';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import activeRoles from '@/public/icons/active-roles.svg';
import inActiveRoles from '@/public/icons/inactive-roles.svg';
import jswOneLogo from '@/public/icons/Logo.svg';
import packageInfo from '@/src/../package.json';
import '@/src/styles/layout.scss';
import { ILooseObject } from '@/src/types/common.interface';

const Layout = () => {
  const [userName, setUserName] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();

  const userData = useSelector((state: RootState) => state?.userDetails);

  const userOrgPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.orgPermissions
  );

  const getUserPermissions = () => {
    return Object.values(userOrgPermissions)?.reduce((acc: string[], permissions: string[]) => {
      return Array.from(new Set([...acc, ...permissions]));
    }, []);
  };

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = () => {
    return Object.values(userAppPermissions)?.reduce((acc: string[], permissions: string[]) => {
      return Array.from(new Set([...acc, ...permissions]));
    }, []);
  };

  const handleLogoutUser = () => {
    clearReduxStore();
    navigate('/login');
  };

  const getMenuIcon = (menuProperties: INavigationMenu, isSelected: boolean) => {
    let iconName = menuProperties?.icon;
    if (!menuProperties?.icon) {
      iconName = isSelected ? menuProperties?.ActiveIcon : menuProperties?.InActiveIcon;
    }

    switch (iconName) {
      case 'users':
        return (
          <Tooltip title={menuProperties?.label}>
            <UsersThree
              weight={isSelected ? 'fill' : 'thin'}
              className={isSelected ? 'activeMenuIcon' : 'inActiveMenuIcon'}
            />
          </Tooltip>
        );
      case 'activeRoles':
        return (
          <Tooltip title={menuProperties?.label}>
            <img src={activeRoles} alt={menuProperties?.label} className="activeMenuIcon" />
          </Tooltip>
        );
      case 'inActiveRoles':
        return (
          <Tooltip title={menuProperties?.label}>
            <img src={inActiveRoles} alt={menuProperties?.label} className="inActiveMenuIcon" />
          </Tooltip>
        );
      case 'config':
        return (
          <Tooltip title={menuProperties?.label}>
            <WebhooksLogo
              weight={isSelected ? 'fill' : 'thin'}
              className={isSelected ? 'activeMenuIcon' : 'inActiveMenuIcon'}
            />
          </Tooltip>
        );

      default:
        break;
    }
  };

  const getMenuItem = (menuItem: INavigationMenu): ReactNode => {
    const isSelected: boolean = menuItem?.applicableRoutes?.includes(location.pathname);
    return (
      <Box key={menuItem.id} onClick={() => navigate(menuItem.route)} sx={{ cursor: 'pointer' }}>
        {getMenuIcon(menuItem, isSelected)}
      </Box>
    );
  };

  useEffect(() => {
    if (userData?.name) {
      setUserName(userData?.name);
    }
  }, [userData?.name]);

  return (
    <Box position="relative">
      <Box className="layoutMenuBar">
        <Box display="flex" flexDirection="row" columnGap={2} alignItems="center">
          <img src={jswOneLogo} height={36} alt="jsw one logo" style={{ paddingBottom: 8 }} />
          <Typography variant="body2" fontWeight={600} fontSize={24}>
            {AppConfig.APP_DISPLAY_NAME}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" columnGap={4}>
          <Typography color="text.secondary">
            Welcome, <b>{userName}</b>
          </Typography>
          <IconButton aria-label="logout" size="small" onClick={handleLogoutUser}>
            <LogoutIcon color="error" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box className="layoutMenuSideBar">
          {getUserPermissions()?.includes('access_uam_portal') ||
          getAppPermissions()?.includes('access_uam_portal')
            ? AppConfig.NAVIGATION_MENUS?.map((menu: INavigationMenu) => {
                const hasPermission =
                  menu?.permissionLevel === 'application'
                    ? getAppPermissions()?.includes(menu?.requiredPermission)
                    : getUserPermissions()?.includes(menu?.requiredPermission);
                if (!hasPermission) return null;
                return <Box key={menu?.id}>{getMenuItem(menu)}</Box>;
              })
            : ''}
          <Box textAlign="center" mb={2} mt="auto">
            <Typography variant="subtitle2">v{packageInfo?.version}</Typography>
            {process.env.REACT_APP_APP_ENV !== 'PROD' && (
              <Chip label={process.env.REACT_APP_APP_ENV} color="warning" size="small" />
            )}
          </Box>
        </Box>
        <Box className="layoutOutlet">
          {getUserPermissions()?.includes('access_uam_portal') ||
          getAppPermissions()?.includes('access_uam_portal') ? (
            <Outlet />
          ) : (
            <NoAuthorization isUnAuthenticated={true} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
