import * as yup from 'yup';

import { IAttributeDef } from '@/src/config/AttributesConfig';
import { ILooseObject } from '@/src/types/common.interface';

class SchemaValidations {
  public static AddUsers(fields: IAttributeDef[]): yup.ObjectSchema<ILooseObject> {
    const shape: ILooseObject = {};

    fields.forEach((field) => {
      let schema: yup.AnySchema;

      // Step 1: Determine the base schema based on value_type
      if (field.value_type === 'string') {
        schema = yup.string().trim();
      } else if (field.value_type === 'number') {
        schema = yup.number().typeError(`${field.ui_label} must be a valid number`);
      } else if (field.value_type === 'boolean') {
        schema = yup.boolean();
      } else {
        schema = yup.mixed();
      }

      // Step 2: Apply mandatory validation if is_mandatory is true
      if (field.is_mandatory) {
        schema = schema.required(`${field.ui_label} is required`);
      } else {
        schema = schema.nullable();
      }

      // Step 2: Apply specific validations for certain fields
      if (field.name === 'name') {
        schema = (schema as yup.StringSchema)
          .min(5, `${field.ui_label} must be at least 5 characters`)
          .max(50, `${field.ui_label} must not exceed 50 characters`);
      } else if (field.name === 'email_id') {
        schema = (schema as yup.StringSchema).matches(
          /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/,
          `${field.ui_label} must be a valid email address`
        );
      } else if (field.name === 'mobile') {
        schema = (schema as yup.StringSchema).test(
          'is-valid-mobile',
          `${field.ui_label} must be a 10-digit number`,
          (value) => /^\d{10}$/.test(value?.toString() || '')
        );
      }

      // Add the schema to the shape object
      shape[field.name] = schema;
    });

    return yup.object().shape(shape);
  }
}

export default SchemaValidations;
